<template>
  <div>
    <CRow>
      <CButton
          key="back"
          class="m-2"
          @click="goBack"
      >
        <CIcon name="cil-arrowLeft" />
        {{ $t('COMMON.BACK_BUTTON') }}
      </CButton>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :balance="0" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="py-3">
            <CRow>
              <CCol>
                <h6 class="text-muted">{{ $t('REFUND.LABEL.REFUND_AMOUNT') }}</h6>
                <span class="h5 font-weight-bold">Rp {{ formatAmount(refundSummary.amount.toString()) }}</span>
              </CCol>
              <CCol>
                <h6 class="text-muted">{{ $t('REFUND.LABEL.PAYMENT_ID') }}</h6>
                <span
                    class="h5 font-weight-bold color-blue"
                    color="info" @click="goTo(`/payments/${refundSummary.paymentId}`)">
                  {{ refundSummary.paymentId }}
                </span>
              </CCol>
              <CCol>
                <h6 class="text-muted">{{ $t('REFUND.LABEL.CUSTOMER_ID') }}</h6>
                <span class="h5 font-weight-bold color-blue"
                      @click="goTo(`/customers/${refundSummary.customerId}`)">
                  {{ refundSummary.customerId }}
                </span>
              </CCol>
              <CCol>
                <h6 class="text-muted">{{ $t('REFUND.LABEL.STATUS') }}</h6>
                <CBadge :color="getRefundBadge(refundSummary.status)">
                  <span class="h6 text-capitalize">{{ $t(`REFUND.STATUS.${refundSummary.status.toUpperCase()}`) }} </span>
                </CBadge>
              </CCol>
              <CCol class="resend-button-col">
                <CButton v-if="refundSummary.status === 'approved'"
                         type="button"
                         @click="emailLinkToCustomer" style="width: 85%"
                         class="btn ml-3 btn-durianprimary btn-lg">
                  {{ $t("REFUND.DETAIL.RESEND_REFUND_LINK") }}
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="py-0">
            <CRow v-if="refundSummary.status === 'rejected'">
              <div class="rejection-notes-banner" id="rejection-notes-banner">
                <span class="rejection-notes-text">{{ $t("REFUND.DETAIL.REJECTION_NOTE") }} : </span>
                <span>{{refundSummary.rejectionNote}}</span>
              </div>
            </CRow>
            <CRow>
              <CCol col="6" class="py-4 border-right">
                <h6 class="h5 font-weight-bold mb-3">{{ $t('REFUND.DETAIL.REFUND_DETAIL') }}</h6>
                <CRow>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.ID") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold color-blue">{{ refundDetail.refundId }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.REFUND_TYPE") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ getCapitalized(refundDetail.refundType) }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.REFUND_CATEGORY") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ getCapitalized(refundDetail.refundPartial) }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.CREATED") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <div>{{ refundDetail.createdName || "-" }}</div>
                    <small>{{ refundDetail.createdAt }}</small>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span  class="font-weight-bold">{{ $t("REFUND.LABEL.UPDATED") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <div>{{ refundDetail.updatedName || "-" }}</div>
                    <small>{{ refundDetail.updatedAt }}</small>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.REFUND_NOTES") || "-" }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ getCapitalized(refundDetail.notes) || "-" }}</span>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="6" class="py-4">
                <h6 class="h5 font-weight-bold mb-3">{{ $t('REFUND.DETAIL.CUSTOMER_REFUND_PREFERENCES') }}</h6>
                <CRow>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.CUSTOMER_NAME") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ customerPreferences.name }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.LABEL.CUSTOMER_EMAIL") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ customerPreferences.email }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.DETAIL.REFUND_DESTINATION") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ getCapitalized(customerPreferences.destination) || "-" }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.DETAIL.ACCOUNT_NUMBER") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ customerPreferences.accountNumber || "-" }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span  class="font-weight-bold">{{ $t("REFUND.DETAIL.ACCOUNT_NAME") }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ getCapitalized(customerPreferences.accountName) || "-" }}</span>
                  </CCol>
                </CRow>
                <h6 class="h5 font-weight-bold my-3">{{ $t('REFUND.DETAIL.DISBURSEMENT_DETAIL') }}</h6>
                <CRow>
                  <CCol col="6" class="mb-3">
                    <span class="font-weight-bold">{{ $t("REFUND.DETAIL.DISBURSEMENT_ID")  }}</span>
                  </CCol>
                  <CCol col="6" class="mb-3">
                    <span>{{ disbursementId || "-" }}</span>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <CRow align-horizontal="between">
              <CCol col="12">
                <h5 class="h5 font-weight-bold float-left">{{ $t("REFUND.DETAIL.REFUND_HISTORY") }}</h5>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="8">
                <div id="content">
                  <ul class="timeline">
                    <li class="event" v-bind:key="event.status" v-for="event in history">
                      <div class="clearfix">
                        <span class="float-left">{{ $t(`REFUND.STATUS.${event.status.toUpperCase()}`) }}</span>
                        <span class="ml-1 text-muted float-right">{{ event.time }}</span>
                        <img src="../../assets/img/timer.svg" class="float-right mt-1"/>
                        <span class="ml-1 mr-4 text-muted float-right">{{ event.date }}</span>
                        <img src="../../assets/img/calendar.svg" class="float-right mt-1" />
                        <span class="ml-1 mr-4 text-muted float-right">{{event.user }}</span>
                        <img src="../../assets/img/user.svg" class="float-right"/>
                      </div>
                    </li>
                  </ul>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div v-if="refundSummary.status === defaultValueStatus && permissions.refundApprove" class="btn-right">
      <button
          v-element-access="permissions.refundReject"
          type="button"
          variant="ghost"
          @click="rejectRefund"
          class="btn float-left ml-3 btn-durianprimary btn-reject btn-lg">
        {{ $t("REFUND.DETAIL.REJECT") }}
      </button>
      <div class="float-left">
        <div id="disabled-wrapper">
          <CButton
              v-element-access="permissions.refundApprove"
              :disabled="this.balanceDisbursement < this.refundSummary.totalAmount"
              type="button"
              @click="approveRefund"
              class="btn ml-3 btn-durianprimary btn-lg">
            {{ $t("REFUND.DETAIL.APPROVE_AND_PAY") }}
          </CButton>
        </div>
        <b-popover
            v-if="this.balanceDisbursement < this.refundSummary.totalAmount"
            custom-class="approval-popover"
            target="disabled-wrapper"
            triggers="hover"
            placement="left"
        >
          <div style="display: flex;">
            <div class="approval-popup-left">
              <img src="../../assets/img/warning-light.svg" width="18" />
            </div>
            <div class="approval-popup-right">
              <i18n path="REFUND.WARNING_INSUFFICIENT_BALANCE" tag="span">
                <template v-slot:topup_text>
                  <span class="approval-warning-topup-text" v-on:click="goTo('/disbursements/topup')"> top up </span>
                </template>
              </i18n>
            </div>
          </div>
        </b-popover>
      </div>
    </div>

    <CModal
        class="modal-approved-refund"
        color="light"
        :no-close-on-backdrop="false"
        :centered="true"
        :show="showApproveModal"
    >
      <div slot="header" />
      <div slot="header-wrapper" />
      <CContainer>
        <div class="modal-title">
          {{ $t("REFUND.MODAL.APPROVE_MODAL_TITLE") }}
        </div>
        <br>
        <CForm>
          <CRow>
            <div class="pop-up-appove-content text-desc">{{ $t("REFUND.MODAL.MESSAGE_1") }}
              <span class="email-approve">{{ this.customerPreferences.email }}</span>
              {{ $t("REFUND.MODAL.MESSAGE_2") }}
            </div>
          </CRow>
          <CRow class="tbl-approve">
            <CCol md="6">{{ $t("REFUND.MODAL.REFUND_AMOUNT") }}</CCol>
            <CCol md="6" class="ta-right">Rp {{  formatAmount(refundSummary.amount.toString()) }}</CCol>
          </CRow>
          <CRow>
            <CCol md="6">{{ $t("REFUND.MODAL.FEES") }}</CCol>
            <CCol md="6" class="ta-right">Rp {{  formatAmount(refundSummary.transferFees.toString()) }}</CCol>
          </CRow>
          <hr class="hr-popup-approve">
          <CRow>
            <CCol md="6">{{ $t("REFUND.LABEL.TOTAL_AMOUNT") }}</CCol>
            <CCol md="6" class="ta-right">Rp {{  formatAmount(refundSummary.totalAmount.toString()) }}</CCol>
          </CRow>
        </CForm>
      </CContainer>
      <template #footer >
        <CRow class="full-width">
          <CCol md="1">
            <img height="32px" width="32px" class="img-succes-refund" src="../../assets/img/wallet.png" />
          </CCol>
          <CCol md="5">
            <small class="text-muted d-block">{{ $t("REFUND.MODAL.BALANCE") }}</small>
            Rp {{  formatAmount(balanceDisbursement) }}
          </CCol>
          <CCol md="6" class="ta-right">
            <CButton @click="closeApprovePopUp">{{ $t("COMMON.CANCEL") }}</CButton>
            <CButton @click="approveRefundEvent" class="btn-durianprimary">{{ $t("REFUND.MODAL.APPROVE_BUTTON") }}</CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>

    <CModal
        class="modal-approved-refund"
        title="Reject this Refund Proposal?"
        color="light"
        :no-close-on-backdrop="false"
        :centered="true"
        :show="showModalReject"
    >
      <CContainer>
        <ValidationObserver ref="validationObserverModal">
          <CForm>
            <CRow>
              <p class="text-desc">{{ $t("REFUND.MODAL.REJECT_DESC") }}</p>
            </CRow>
            <CRow>
              <ValidationProvider
                  name="Reason"
                  rules="required"
                  class="form-group col-12 px-0"
                  v-slot="{ errors }"
              >
                <CTextarea
                    placeholder=""
                    class="col-12 px-0 mb-0"
                    rows="5"
                    v-model="reason_reject"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CContainer>
      <template #footer >
        <CButton @click="closeRejectPopUp">{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton @click="rejectRefundEvent" class="btn-durianprimary">{{ $t("REFUND.DETAIL.REJECT") }}</CButton>
      </template>
    </CModal>

    <CModal
        color="light"
        :no-close-on-backdrop="false"
        :centered="true"
        :show="showModalSuccessRefund"
        class="succes-approve-refund"
    >
      <CContainer class="container-success-approve">
        <CRow >
          <img class="img-succes-refund mb-lg-4" src="../../assets/img/success.svg" width="15%" />
        </CRow>
        <CRow>
          <p class="title-approve">{{ $t("REFUND.MODAL.APPROVED_TITLE") }}</p>
          <div class="approve-succes-content text-desc">{{ $t("REFUND.MODAL.APPROVED_DESC") }}</div>
          <div class="table-responsive">
            <table class="table borderless table-succes-refund" >
              <thead class="container-header-refund">
              <tr>
                <td>{{ $t("REFUND.LABEL.REFUND_ID") }}</td>
                <td class="ta-right succes-approve-popup">{{ refundDetail.refundId }}</td>
              </tr>
              </thead>
              <tbody class="container-table-body-refund">
              <tr>
                <td>{{ $t("REFUND.LABEL.CUSTOMER_NAME") }}</td>
                <td class="ta-right succes-approve-popup">{{ customerPreferences.name }}</td>
              </tr>
              <tr>
                <td>{{ $t("REFUND.LABEL.TOTAL_AMOUNT") }}</td>
                <td class="ta-right succes-approve-popup">Rp {{  formatAmount(refundSummary.totalAmount.toString()) }}</td>
              </tr>
              <tr>
                <td>{{ $t("REFUND.LABEL.REFUND_NOTES") }}</td>
                <td class="ta-right succes-approve-popup">{{ refundDetail.notes || "-" }} </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CRow>
      </CContainer>
      <template #footer >
        <CButton @click="closeSuccessPopUp" class="btn-durianprimary">
          {{ $t("REFUND.MODAL.GOT_IT_BUTTON") }}
        </CButton>
      </template>
    </CModal>

    <CModal
        color="light"
        :show="showModalResendEmail"
    >
      <div slot="header" />
      <div slot="header-wrapper" />
        <div class="modal-title">
          {{ $t("REFUND.MODAL.RESEND_REFUND_LINK_MODAL_TITLE") }}
        </div>
        <br>
        <span>{{ $t("REFUND.MODAL.RESEND_LINK_EMAIL_MESSAGE") }}</span>
        <span class="resend-refund-email-text"> {{ customerPreferences.email }}</span>
      <template #footer >
        <CButton @click="closeResendLinkPopUp" style="font-weight: 700">{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton @click="resendEMailLink" class="btn-durianprimary">
          {{ $t("REFUND.MODAL.RESEND_LINK_EMAIL_CONFIRM_BUTTON") }}
        </CButton>
      </template>
    </CModal>

    <CModal
        color="light"
        :show="showModalResendEmailSuccess"
    >
      <div slot="header" />
      <div slot="header-wrapper" />
        <div class="modal-title">
          {{ $t("REFUND.MODAL.RESEND_REFUND_LINK_SUCCESS_TITLE") }}
        </div>
        <br>
        <span>{{ $t("REFUND.MODAL.RESEND_LINK_EMAIL_SUCCESS_MESSAGE") }}</span>
      <template #footer >
        <CButton @click="closeResendSuccessPopup" class="btn-durianprimary">
          {{ $t("REFUND.MODAL.GOT_IT_BUTTON") }}
        </CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
const REFUND_REJECTED = "rejected";
import BalanceCard from '../disbursements/components/BalanceCard.vue';
import { getBalance, getTransferFees } from "@/api/disbursement.api.js";
import { getRefundDetail, approveRefund, rejectRefund, resendEmailLinkToCustomer } from "@/api/refunds.api.js";

export default {
  components: { BalanceCard },
  name: "RefundDetail",
  data() {
    return {
      reason_reject: "",
      defaultValueStatus: "started",
      showApproveModal: false,
      showModalReject: false,
      showModalSuccessRefund: false,
      showModalResendEmail: false,
      showModalResendEmailSuccess: false,
      balanceDisbursement: 0,
      refundStatus: [
        "all",
        "started",
        "rejected",
        "processing",
        "done",
        "failed",
        "disbursement_processing",
        "approved"
      ],
      refundSummary: {
        amount: 0,
        paymentId: '',
        customerId: '',
        status: '',
        rejectionNote: '',
        transferFees: 0,
        totalAmount: 0,
      },
      refundDetail: {
        refundId: "",
        refundRefNumber: "",
        refundType: "",
        refundPartial: "",
        createdBy: "",
        createdName: "",
        createdAt: "",
        updatedBy: "",
        updatedAt: "",
        updatedName: "",
        notes: '',
      },
      customerPreferences: {
        name: "",
        email: "",
        destination: null,
        accountNumber: "",
        inquiryName: "",
        accountName: "",
      },
      disbursementId: null,
      history: [],
      isApproveButtonDisabled: false,
    };
  },
  methods: {
    getRefundDetail() {
      let id = this.$route.params.id;
      getRefundDetail(id)
          .then(response => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              try {
                let data = response.data.data;
                this.refundDetail.refundId = data.id;
                this.refundDetail.refundRefNumber = data.refund_ref_id;
                this.refundDetail.refundType = data.type;
                this.refundDetail.refundPartial = data.refund_partial;
                this.refundDetail.createdAt = this.formatDate(data.created_at);
                this.refundDetail.updatedAt = this.formatDate(data.updated_at);
                this.refundDetail.notes = data.refund_notes;
                this.refundSummary.totalAmount = parseInt(this.refundSummary.transferFees, 10) + parseInt(data.total_amount, 10);
                this.refundDetail.createdName = data.created_by_name;
                this.refundDetail.updatedName = data.updated_by_name;

                this.refundSummary.status = data.status;
                this.refundSummary.amount = data.total_amount;
                this.refundSummary.customerId = data.customer_id;
                this.refundSummary.paymentId = data.payment_id;

                this.customerPreferences.name = data.customer_name;
                this.customerPreferences.email = data.customer_email;
                this.customerPreferences.destination = data.destination;
                this.customerPreferences.inquiryName = data.account_name;
                this.customerPreferences.accountName = this.maskText(data.account_name);
                this.customerPreferences.accountNumber = data.account_number;
                this.disbursementId = data.disbursement_id;
                this.history = [];
                for(let i = 0; i < data.history.length; i++) {
                  let d = new Date(data.history[i].updated_at);
                  let date = this.moment(d).format("DD MMM YYYY");
                  let time = this.moment(d).format('LT');
                  this.history.push({ status: data.history[i].status, user: data.history[i].updated_name, date: date, time: time })
                }
                const rejectedNotes = data.history.filter(item => item.status === REFUND_REJECTED);
                this.refundSummary.rejectionNote = (rejectedNotes && rejectedNotes.length > 0) ?
                    rejectedNotes[rejectedNotes.length - 1].note : "";

              } catch (error) {
                this.showToaster(error);
              }
            }
          })
          .catch(this.handleAPIError);
    },
    getTransferFees() {
      getTransferFees()
          .then(response => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              this.refundSummary.totalAmount = parseInt(this.refundSummary.totalAmount, 10) + parseInt(response.data.data, 10);
              this.refundSummary.transferFees = response.data.data;
            }
          })
          .catch( err => {
            console.error(err);
            this.showToaster("Error fetching transfer fees, please reload the page. If the issue persists, please contact admin")
          });
    },
    rejectRefund(){
      this.showModalReject = true;
    },
    approveRefund(){
      this.showApproveModal = true;
    },
    closeRejectPopUp(){
      this.showModalReject = false;
    },
    closeApprovePopUp(){
      this.showApproveModal = false;
    },
    closeSuccessPopUp(){
      this.showModalSuccessRefund = false;
    },
    emailLinkToCustomer() {
      this.showModalResendEmail = true;
    },
    closeResendLinkPopUp() {
      this.showModalResendEmail = false;
    },
    refundLinkResent() {
      this.showModalResendEmail = false;
      this.showModalResendEmailSuccess = true;
    },
    closeResendSuccessPopup() {
      this.showModalResendEmailSuccess = false;
    },
    resendEMailLink() {
      resendEmailLinkToCustomer(this.refundDetail.refundId)
          .then(() => {
            this.refundLinkResent();
          })
          .catch(this.handleAPIError)
    },
    approveRefundEvent() {
      approveRefund(this.refundDetail.refundId)
          .then(() => {
            try {
              this.showApproveModal = false;
              this.showModalSuccessRefund = true;
              this.getRefundDetail();
            } catch (error) {
              this.showToaster(error)
            }
          }).catch(this.handleAPIError);
    },
    rejectRefundEvent() {

      rejectRefund(this.refundDetail.refundId, {
        "note" : this.reason_reject
      })
          .then(() => {
            try {
              this.showModalReject = false;
              this.getRefundDetail();
            } catch (error) {
              this.showToaster(error)
            }
          }).catch(this.handleAPIError);

    },
    getUserBalance() {
      getBalance()
          .then((response) => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              this.balanceDisbursement = parseInt(response.data.data.balance, 10);
            } else {
              this.showToaster(
                  this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BALANCE")
              );
            }
          })
          .catch(this.handleAPIError);
    },
  },
  mounted() {
    this.getRefundDetail();
    this.getUserBalance();
    this.getTransferFees();
  },
};
</script>
<style scoped>
.btn-reject {
  background-color: transparent !important;
  border: none;
  color: #444;
  box-shadow: none;
}

.table-responsive {
  border: 1px solid rgb(186 186 186 / 90%) !important;
  border-radius: 10px
}

.approve-succes-content {
  text-align: center;
}

.modal-body {
  padding-top: 0px;
}

.text-desc {
  color: #818386
}

.approval-popover {
  display: inline-flex;
  margin: auto;
  justify-content: center;
  align-content: center;
  border: none;
  background-color: #FAD8DC;
}

.approval-popup-left {
  margin: auto auto;
  width: 10%;
  float: left;
}

.approval-popup-right {
  float: left;
  width: 90%;
  color: #E86163;
}

.approval-warning-topup-text {
  color:#3C7DE1;
  cursor: pointer;
  font-weight: 700;
}

.resend-email-div {
  display: flex;
  flex-direction: column-reverse;
}

.resend-refund-email-text {
  color: #418af8;
  font-weight: 700;
}

.resend-button-col {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
</style>